import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, Modal, Typography } from '@mui/material';
import React, { Component } from 'react';
import './App.css';
import PasienBaru from './components/pasienBaru';
import PasienLama from './components/pasienLama';
import { Close } from '@mui/icons-material';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

class App extends Component {
  state = {
    baru: false,
    lama: false
  }

  _handleClick = (val) => (e) => {
    e.preventDefault();
    if (val === 'pasienBaru') {
      this.setState({ baru: true, lama: false });
    } else {
      this.setState({ baru: false, lama: true });
    }
  }

  _handleClose = () => {
    this.setState({ baru: false, lama: false });
  }

  render() {
    const { baru, lama } = this.state;
    return (
      <div className='containerMainMenu'>
        <Modal open={baru}>
          <Box sx={styleModal}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <div style={{
                position: 'absolute',
                top: '-30px',
                right: '-20px',
                backgroundColor: 'white',
                borderRadius: '50%'
              }}>
                <IconButton onClick={this._handleClose}>
                  <Close />
                </IconButton>
              </div>
              <PasienBaru />
            </div>
          </Box>
        </Modal>
        <Modal open={lama}>
          <Box sx={styleModal}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <div style={{
                position: 'absolute',
                top: '-30px',
                right: '-20px',
                backgroundColor: 'white',
                borderRadius: '50%'
              }}>
                <IconButton onClick={this._handleClose}>
                  <Close />
                </IconButton>
              </div>
              <PasienLama />
            </div>
          </Box>
        </Modal>
        <div className='animatedText'><span></span></div>
        <div className='wrappMainMenu'>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card onClick={this._handleClick('pasienBaru')} sx={{ boxShadow: 24 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image="https://citrakasih.sch.id/wp-content/uploads/2020/09/daftar-step1-300x300.png"
                    height="150"
                    sx={{ objectFit: 'contain' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Pasien Baru
                    </Typography>
                    <Typography variant="body2">
                      Silahkan Akses Menu ini, Untuk Calon Pasien Yang <b>Belum Pernah</b> Berobat Sebelumnya ke RS Hermina Arcamanik Bandung. <b>Belum</b> Memiliki KIP (Kartu Identitas Pasien) dan atau <b>Belum</b> Memiliki Nomor Medical Record di RS Hermina Arcamanik Bandung.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card onClick={this._handleClick('pasienLama')} sx={{ boxShadow: 24 }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image="https://daftar.rsulin.kalselprov.go.id/assets/frontend/images/mbr-1050x1190.png"
                    height="150"
                    sx={{ objectFit: 'contain' }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Pasien Lama
                    </Typography>
                    <Typography variant="body2">
                      Silahkan Akses Menu Ini, Untuk Pasien Yang <b>Sudah Pernah</b> Berobat Sebelumnya di RS Hermina Arcamanik Bandung, <b>Sudah</b> Memiliki KIP (Kartu Identitas Pasien) dan atau <b>Sudah</b> Memiliki Nomor Medical Record di RS Hermina Arcamanik Bandung.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default App;
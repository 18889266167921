import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment/moment';
import React, { Component } from 'react';

const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 30
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableCol: {
    width: "120px",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColTitikDua: {
    width: "10px",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableColRegist: {
    width: "120px",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    marginTop: 5,
    fontSize: 10,
    padding: 5
  },
  tableCellKodeRegist: {
    marginTop: 5,
    fontSize: 13,
    fontWeight: 'bold',
    backgroundColor: 'red',
    color: 'white',
    padding: 5,
  },
});

class PreviewDoc extends Component {
  render() {
    const { data, pasienLama } = this.props;
    let nomberAsuransi = '';
    if (pasienLama) {
      if (data?.dataPasien?.asuransi === 'Umum') {
        nomberAsuransi = '-';
      } else {
        nomberAsuransi = data?.dataPasien?.noAsuransi;
      }
    } else {
      if (data?.asuransi === 'Umum') {
        nomberAsuransi = '-';
      } else {
        nomberAsuransi = data?.noAsuransi;
      }
    }
    return (
      <div>
        <div style={{ borderBottom: '3px solid black', textAlign: 'right', padding: 5 }}>
          <Text style={{ fontSize: 18 }}>Hermina Arcamanik Hospital (RSU Hermina Arcamanik)</Text>
          <Text>Jl. A.H. Nasution No.50, Antapani Wetan, Kec. Antapani</Text>
          <Text>Kota Bandung, Jawa Barat 40291</Text>
          <Text>Phone: 022-87242525. Fax: 022-7271771</Text>
        </div>
        <div style={{ paddingTop: 30 }}>
          <Text style={{ fontSize: 16, textAlign: 'center' }}>BUKTI PENDAFTARAN</Text>
          <Text style={{ fontSize: 16, textAlign: 'center' }}>RAWAT JALAN</Text>
        </div>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellKodeRegist}>Kode Registrasi</Text>
            </View>
            <View style={styles.tableColTitikDua}>
              <Text style={styles.tableCellKodeRegist}>:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellKodeRegist}>{data?.noRegistrasi}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>No RM</Text>
            </View>
            <View style={styles.tableColTitikDua}>
              <Text style={styles.tableCell}>:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data?.noRM}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Nama Lengkap</Text>
            </View>
            <View style={styles.tableColTitikDua}>
              <Text style={styles.tableCell}>:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{pasienLama ? data?.dataPasien?.namaLengkap : data?.namaLengkap}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Jenis Asuransi</Text>
            </View>
            <View style={styles.tableColTitikDua}>
              <Text style={styles.tableCell}>:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{pasienLama ? data?.dataPasien?.asuransi : data?.asuransi}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>No Asuransi</Text>
            </View>
            <View style={styles.tableColTitikDua}>
              <Text style={styles.tableCell}>:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{nomberAsuransi}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>No Rujukan</Text>
            </View>
            <View style={styles.tableColTitikDua}>
              <Text style={styles.tableCell}>:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data?.noRujukan}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Tujuan Poliklinik</Text>
            </View>
            <View style={styles.tableColTitikDua}>
              <Text style={styles.tableCell}>:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data?.poli}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Tanggal Berkunjung</Text>
            </View>
            <View style={styles.tableColTitikDua}>
              <Text style={styles.tableCell}>:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{moment(data?.tglBerkunjung).format('DD-MM-YYYY')}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Jam Berkunjung</Text>
            </View>
            <View style={styles.tableColTitikDua}>
              <Text style={styles.tableCell}>:</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{data?.jamBerkunjung}</Text>
            </View>
          </View>
        </View>
      </div>
    );
  }
}

export default PreviewDoc;